<template>
  <v-card
    outlined
    class="fill-height"
  >
    <v-card-title>
      {{ $t("Adresses") }}
      <v-spacer />
      <v-dialog
        v-model="isDialogVisible"
        persistent
        max-width="600px"
      >
        <template #activator="{ on, attrs }">
          <v-btn
            outlined
            small
            color="primary"
            v-bind="attrs"
            v-on="on"
            @click="editItem({phone: author.phone})"
          >
            {{ $t("Ajouter") }}
          </v-btn>
        </template>

        <v-card>
          <v-form
            ref="form"
            v-model="valid"
            @submit.prevent="onSubmit"
          >
            <v-card-title>
              <span class="headline">{{ item.id?$t('Update'):$t("Ajouter") }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-text-field
                      v-model="item.address"
                      outlined
                      dense
                      :rules="[validators.required]"
                      :error-messages="errorMessages.address"
                      :label="$t('Adresse')"
                      :placeholder="$t('Adresse')"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-text-field
                      v-model="item.address2"
                      outlined
                      dense
                      :rules="[validators.required]"
                      :error-messages="errorMessages.address"
                      :label="$t('Adresse 2')"
                      :placeholder="$t('Adresse 2')"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-text-field
                      v-model="item.phone"
                      outlined
                      dense
                      :error-messages="errorMessages.phone"
                      :label="$t('Téléphone')"
                      :placeholder="$t('Téléphone')"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-autocomplete
                      v-model="item.country_id"
                      :label="$t('Pays')"
                      :rules="[validators.required]"
                      :error-messages="errorMessages.country_id"
                      :items="$store.state['app'].pays"
                      item-text="name"
                      item-value="id"
                      outlined
                      dense
                      hide-details="auto"
                      clearable
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-text-field
                      v-model="item.city"
                      outlined
                      dense
                      :rules="[validators.required]"
                      :error-messages="errorMessages.city"
                      :label="$t('Ville')"
                      :placeholder="$t('Ville')"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-text-field
                      v-model="item.department"
                      outlined
                      dense
                      :error-messages="errorMessages.department"
                      :label="$t('Departement')"
                      :placeholder="$t('Departement')"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-text-field
                      v-model="item.postal_code"
                      outlined
                      dense
                      :error-messages="errorMessages.postal_code"
                      :label="$t('Code postal')"
                      :placeholder="$t('Code postal')"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-btn
                color="error"
                outlined
                @click="isDialogVisible = false"
              >
                {{ $t("Cancel") }}
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                color="success"
                type="submit"
              >
                {{ $t("Ajouter") }}
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </v-card-title>
    <v-divider />
    <v-list three-line>
      <v-slide-y-transition
        class="py-0"
        group
        tag="v-list"
      >
        <template v-for="(address, index) in auteur.addresses">
          <v-list-item
            :key="`${index}-${address.name}`"
            @click="editItem(address)"
          >
            <template>
              <v-list-item-content>
                <v-list-item-title
                  class="primary--text"
                  v-text="address.address"
                ></v-list-item-title>
                <v-list-item-title
                  class="primary--text"
                  v-text="address.address2"
                ></v-list-item-title>

                <v-list-item-subtitle
                  v-if="address.country && address.country.name"
                  class="primary--text"
                  v-text="address.country.name"
                ></v-list-item-subtitle>

                <v-list-item-subtitle
                  class="grey--text"
                  v-text="address.city"
                ></v-list-item-subtitle>
                <v-list-item-subtitle v-text="address.postal_code"></v-list-item-subtitle>
                <v-list-item-subtitle
                  class="grey--text"
                  v-text="address.phone"
                ></v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  icon
                  small
                  @click.stop="editItem(address, index)"
                >
                  <v-icon small>
                    {{ mdiPencilOutline }}
                  </v-icon>
                </v-btn>
                <v-btn
                  icon
                  small
                  color="error"
                  @click.stop="deleteItem(address, index)"
                >
                  <v-icon small>
                    {{ mdiDelete }}
                  </v-icon>
                </v-btn>
              </v-list-item-action>
            </template>
          </v-list-item>

          <v-divider
            v-if="index < auteur.addresses.length - 1"
            :key="index"
          ></v-divider>
        </template>
      </v-slide-y-transition>
    </v-list>
  </v-card>
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import {
  mdiStarOutline, mdiStar, mdiDelete, mdiPencilOutline,
} from '@mdi/js'
import { required, emailValidator } from '@core/utils/validation'
import { getIndex, getItem } from '@core/utils'
import axios from '@axios'
import store from '@/store'

export default {
  components: { },
  model: {
    prop: 'author',
    event: 'update:author',
  },
  props: {
    author: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const item = ref({})
    const errorMessages = ref({ })
    const selected = ref([0])
    const isDialogVisible = ref(false)
    const valid = ref(false)
    const form = ref()
    const validate = () => {
      form.value.validate()
    }

    const auteur = computed({
      get: () => props.author,
      set: value => emit('input', value),
      options: { deep: true },
    })

    auteur.value.addresses = auteur.value.addresses || []
    const editItem = address => {
      item.value = address
      isDialogVisible.value = true
    }
    const deleteItem = (address, index) => {
      if (address.id != null) {
        store.dispatch('app-author/deleteAddress', address)
          .then(response => {
          }).catch(error => {

          })
      }
      auteur.value.addresses.splice(index, 1)
      emit('input', auteur.value)
    }
    const onSubmit = () => {
      if (valid.value) {
        if (item.value.id != null || item.value.index != null) {
          if (item.value.id != null) {
            const index = getIndex(auteur.value.addresses, 'id', item.value.id)
            if (index > -1) {
              auteur.value.addresses[index] = item
            }
          } else {
            const index = getIndex(auteur.value.addresses, 'index', item.value.index)
            if (index > -1) {
              auteur.value.addresses[index] = item
            }
          }
        } else {
          console.log('item', item.value)
          item.value.index = auteur.value.addresses.length
          auteur.value.addresses.unshift(item.value)
        }
        isDialogVisible.value = false
        emit('input', auteur.value)

        axios.post('/addresses', {...item.value, author_id: auteur.value.id})
          .then(response => {
            item.value = response.data
            if (item.value.id != null || item.value.index != null) {
              if (item.value.id != null) {
                const index = getIndex(auteur.value.addresses, 'id', item.value.id)
                if (index > -1) {
                  auteur.value.addresses[index] = item
                }
              } else {
                const index = getIndex(auteur.value.addresses, 'index', item.value.index)
                if (index > -1) {
                  auteur.value.addresses[index] = item
                }
              }
            } else {
              console.log('item', item.value)
              item.value.index = auteur.value.addresses.length
              auteur.value.addresses.unshift(item.value)
            }
            emit('input', auteur.value)
          }).catch(error => {
            console.log('error', error)
          })
      } else {
        validate()
      }
    }

    return {
      selected,
      item,
      auteur,
      isDialogVisible,
      errorMessages,
      mdiPencilOutline,
      mdiStarOutline,
      mdiStar,
      mdiDelete,
      getItem,
      onSubmit,
      editItem,
      deleteItem,
      form,
      valid,
      validators: { required, emailValidator },
    }
  },
}
</script>

<template>
  <div
    :style="setBackgroundColor"
    :class="{'cursor-pointer': th.editable && canEdit,...additionalClasses, ...(item.classes?(item.classes[th.value] ): {}) }"
    @mouseover="hover"
    @mouseleave="leave"
    @click="edit"
  >
    <slot
      name="data"
      :item="item"
    >
      <template v-if="th.editable && (getItemValue(item, th.value,null) === null || th.translatable && getItemValue(item, th.value,[]).length === 0)&& canEdit">
        <span v-if="th.hideEdit">
          &nbsp;
        </span>
        <template v-else >
          <v-icon color="primary">
            {{ icons.mdiCheckboxMarkedCircleOutline }}
          </v-icon>
        </template>
      </template>
      <template v-else>
        <template v-if="th.translatable">
          <div v-if="th.multiple" >
            <translatable-field :key="ind" v-for="(t, ind) in getItemValue(item, th.value,'')" :object="getItemValue(t, th.itemLabel,'')" :classes="th.cellClass"/>
          </div>
          <translatable-field v-else :object="getItemValue(item, th.value,'')" :classes="th.cellClass"/>
        </template>
        <span
          v-else-if="th.type === 'date'"
        >{{ !th.hideValue? (formatDateShort(getItemValue(item, th.value) || null, th.date_format || { month: 'numeric', day: 'numeric', year: 'numeric'})):'' }}</span>
        <template v-else-if="th.type === 'text' ">
          <translatable-field v-if="th.translatable" :object="getItemValue(item, th.value,'')" :classes="th.cellClass"/>
          <span v-else>
          {{ !th.hideValue?getItemValue(item, th.value,''):'' }}
        </span>
        </template>
        <span v-else-if="th.type === 'check'">
          <v-icon
            v-if=" !th.hideValue && getItemValue(item, th.value) === th.trueValue"
            small
            color="success"
          >
              {{ icons.mdiCheckCircle }}
            </v-icon>
          <span v-else>
            <span v-if="!comments.length">&nbsp;</span>
          </span>
        </span>
        <span v-else-if="th.type === 'select' && !th.source">
          <template v-if="th.multiple">
            <div  v-for="(t, ind) in getItemValue(item, th.value,'')">
            {{ getItemValue(t, th.itemLabel,'') }}
             </div>
          </template>
          <div v-else>
            {{ getItem(th.items, 'id', getItemValue(item, th.selected_value || th.value) || 0)[th.selected_label || 'label'] }}
          </div>
        </span>
        <span v-else-if="th.type === 'select' && th.source">
           <template v-if="th.multiple">
            <div  v-for="(t, ind) in getItemValue(item, th.value,'')">
            {{ getItemValue(t, th.itemLabel,'') }}
             </div>
          </template>
          <div v-else>
           {{ getItemValue(item, th.value) }}
          </div>

        </span>
        <span v-else-if="th.type === 'color'">
          &nbsp;
        </span>
        <v-img v-else-if="th.type === 'image'" :src="getItemValue(item, th.value)" :lazy-src="appLogo" height="30" width="50" class="align-center" cover />

        <template v-else >
          <translatable-field v-if="th.translatable" :object="getItemValue(item, th.value,'')" :classes="th.cellClass" />
          <span v-else>
          {{ !th.hideValue?getItemValue(item, th.value,''):'' }}
        </span>
        </template>
      </template>
    </slot>
    <v-icon
      v-if="comments.length"
      x-small
      color="info"
      class="ml-2"
    >
      {{ icons.mdiInformation }}
    </v-icon>
    <v-tooltip
      v-model="tooltip"
      bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <span
          v-bind="attrs"
          v-on="on"
        ></span>
      </template>
      <template>
        <div v-if="comments.length">
          <p
            v-for="comment in comments"

            :key="comment.id"
          >
            <span class="mr-2">{{ comment.user?comment.user.code:'' }}</span>
            <span
              v-if="comment.date_record || comment.created_at"
              class="mr-2"
            >{{ formatDateShort((comment.date_record?comment.date_record:(comment.created_at || null)), { month: 'numeric', day: 'numeric' }) }}</span>
            <span class="mr-2">{{ comment.comment }}</span>
          </p>
        </div>
      </template>
    </v-tooltip>
    <v-dialog
      v-model="isFormActive"
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 500 : '100%'"
    >
      <CellForm
        v-if="isFormActive"
        v-model="item"
        :options="th"
        :config="config"
        :is-form-active="isFormActive"
        @saved="saved"
      >
        <template v-slot:form-title>
          <span class="font-weight-semibold text-base text--primary">{{ $t('Editing of')  }} "{{th.text}}"</span>
          <v-spacer />
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="isFormActive = !isFormActive"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </template>
        <template v-slot:form-cancel>
          <v-btn
            color="secondary"
            outlined
            type="reset"
            @click="isFormActive = !isFormActive"
          >
            {{ $t('Cancel') }}
          </v-btn>
        </template>
      </CellForm>
    </v-dialog>
  </div>
</template>

<script>

// eslint-disable-next-line object-curly-newline
import {
  mdiCalendar,
  mdiCalendarRange,
  mdiFilter,
  mdiClose,
  mdiInformation,
  mdiCheckboxMarkedCircleOutline,
  mdiStar,
  mdiCheckCircle,
} from '@mdi/js'
import { computed, ref, watch } from '@vue/composition-api'
import { avatarText, formatDate } from '@core/utils/filter'
import CellForm from '@/components/CellForm'
import ability from '@/plugins/acl/ability'
import themeConfig from '@themeConfig'
import TranslatableField from "@/components/TranslatableField";

export default {
  components: {TranslatableField, CellForm },
  props: {
    options: {
      type: Object,
      default: () => {},
    },
    config: {
      type: Object,
      default: () => ({ url: '/' }),
    },
    th: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const today = ref((new Date(Date.now())).toISOString().substr(0, 10))
    const tooltip = ref(false)
    const isFormActive = ref(false)
    const item = computed({
      get: () => props.options,
      set: value => emit('update:object', value),
    }, { deep: true })

    const additionalClasses = ref({})
    const colors = ref([])
    const comments = ref([])
    const setComments = data => {
      if (data && data[props.th.comments] && data[props.th.comments].length) {
        comments.value = data[props.th.comments].filter(el => el[props.th.comment_reference || 'column_name'] === (props.th.comment_column || props.th.value) && el.comment && el.comment.trim().length)
      } else {
        comments.value = []
      }
    }
    setComments(item.value)
    const setColors = data => {
      if (data[props.th.colors] && data[props.th.colors].length) {
        colors.value = data[props.th.colors].filter(el => el[props.th.color_reference || 'column_name'] === (props.th.color_column || props.th.value) && el.color)
      } else {
        colors.value = []
      }
    }
    setColors(item.value)
    const setColor = () => {
      if (item.value[props.th.colors] && item.value[props.th.colors].length > 0) {
        const records = item.value[props.th.colors].filter(ele => ele[props.th.color_reference || 'column_name'] === (props.th.color_column || props.th.value))

        if (records && records.length) {
          item.value.color_id = records[0].color_id || null
          item.value.fore_color_id = records[0].fore_color_id || null
        }
      } else {
        item.value.color_id = null
      }
    }
    setColor()

    const executeFunctions = () => {
      if (props.th.functions && props.th.functions.length) {
        for (let i = 0; i < props.th.functions.length; i++) {
          (props.th.functions[i])(props.th, item.value)
        }
      }
    }
    executeFunctions()
    watch(item, val => {
      emit('update:object', val)
      setColors(val)
      setComments(val)
      executeFunctions()

      // setColor()
    }, { deep: true })

    const formatDateShort = (date, option) => formatDate(date, option)
    const canEdit = computed(
      {
        get: ()=> {
          if (props.th.editable && !(item.value.denieds && item.value.denieds[props.th.value])) {
            //if(props.th.editPermission) {
            //return ability.can(props.th.editPermission, 'Communication')
            return ability.can('edit_' + props.th.value, props.config.resource)
            //}
            //return true
          }
        }
      }
    )
    const edit = () => {
      if (canEdit.value) {
        item.value.color_id = null
        setColor()
        console.log('color', item.value.color_id)
        item.value.comment = null
        isFormActive.value = true
      }
    }
    const hover = () => {
      if (comments.value.length) {
        tooltip.value = true
      }
    }
    const leave = () => {
      tooltip.value = false
    }
    const saved = data => {
      emit('update:object', data)
      emit('saved', data)
      item.value = data
      setColors(data)
      setComments(data)
      setColor()
      executeFunctions()
      isFormActive.value = false
    }
    const setBackgroundColor = computed({
      get: () => {
        if (!colors) return {}

        if (colors.value.length > 0) {
          const records = colors.value.filter(ele => (props.th.color_column || props.th.value) === ele[props.th.color_reference || 'column_name'])

          if (!records.length || !records[0].color_id) return {}

          return {
            'background-color': records[0].color.bg_color,
            color: records[0].fore_color_id ? records[0].forecolor.bg_color : '#000000'/* records[0].color.txt_color */,
            height: '100%',
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'center',
          }
        }
      },
    })

    return {
      formatDate,
      formatDateShort,
      additionalClasses,
      saved,
      canEdit,
      setBackgroundColor,
      item,
      isFormActive,
      edit,
      hover,
      leave,
      comments,
      colors,
      today,
      tooltip,
      appLogo: themeConfig.app.logo,
      icons: {
        mdiCalendar,
        mdiCalendarRange,
        mdiFilter,
        mdiInformation,
        mdiClose,
        mdiCheckboxMarkedCircleOutline,
        mdiStar,
        mdiCheckCircle,
      },
    }
  },
}
</script>

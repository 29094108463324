<template>
  <v-text-field
    v-model="item[locale]"
    v-bind="$attrs"
  >
    <template #append>
      <v-menu
        offset-y
        min-width="175"
        left
        :elevation="$vuetify.theme.dark ? 9 : 8"
      >
        <!-- Activator -->
        <template v-slot:activator="{ on, attrs }">

            <v-img
              :src="require(`@/assets/images/flags/${locale}.png`)"
              :alt="locale"
              height="16px"
              width="22px"
              v-bind="attrs"
              v-on="on"
              class="me-2 my-1"
            ></v-img>

        </template>

        <!-- Options List -->
        <v-list>
          <v-list-item-group
            :value="locale"
            @change="updateActiveLocale"
          >
            <v-list-item
              v-for="lang in $store.state.app.locales"
              :key="lang.locale"
              :value="lang.locale"
            >
              <v-img
                :src="lang.img"
                height="14px"
                width="22px"
                :alt="lang.locale"
                class="me-2"
              ></v-img>
              <v-list-item-title>{{ lang.title }}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </template>
  </v-text-field>
</template>

<script>

import { computed, ref, watch } from '@vue/composition-api'

import { i18n } from '@/plugins/i18n'

export default {
  model: {
    prop: 'object',
  },
  props: {
    object: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const item = computed({
      get: () => props.object,
      set: value => emit('input', value),
    })

    item.value = item.value || {}
    const locale = ref(i18n.locale)
    const updateActiveLocale = lang => {
      locale.value = lang
    }
    const locales = [
      {
        title: 'English',
        img: require('@/assets/images/flags/en.png'),
        locale: 'en',
      },
      {
        title: 'French',
        img: require('@/assets/images/flags/fr.png'),
        locale: 'fr',
      },

      /* {
        title: 'Arabic',
        img: require('@/assets/images/flags/ar.png'),
        locale: 'ar',
      }, */
    ]

    return {
      locale,
      locales,
      updateActiveLocale,
      item,
    }
  },
}
</script>

<template>
  <div id="subscription-list">
    <!-- app drawer -->

    <v-dialog
      v-model="isFormActive"
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 700 : '100%'"
    >
      <subscription-form
        v-model="item"
        :is-form-active="isFormActive"
        :pay-options="payOptions"
        :plan-options="planOptions"
        @refetch-data="fetchItems"
      >
        <template v-slot:form-title>
          <span class="font-weight-semibold text-base text--primary">{{ item.id ? $t('Update'): $t('Add subscription') }}</span>
          <v-spacer />
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="isFormActive = !isFormActive"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </template>
        <template v-slot:form-cancel>
          <v-btn
            color="secondary"
            outlined
            type="reset"
            @click="isFormActive = !isFormActive"
          >
            {{ $t('Cancel') }}
          </v-btn>
        </template>
      </subscription-form>
    </v-dialog>

    <!-- list filters -->
    <v-card>
      <v-card-title>
        {{ $t("Subscriptions") }}<v-chip outlined class="ml-2">{{ totalItems }}</v-chip>
        <v-spacer />
<!--        <export-excel
          :options="downloadObject"
          @refetch-data="fetchItems"
        />-->
        <v-btn
          v-if="$can('edit','Subscription')"
          color="primary"
          class=" me-3"
          @click.stop="openDialog(false)"
        >
          <v-icon>{{ icons.mdiPlus }}</v-icon>
          <span>{{ $t("Add a Subscription") }}</span>
        </v-btn>
      </v-card-title>

      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        class="fixed-table subscriptions-table"
        :headers="filters"
        :items="items"
        :options.sync="options"
        :page.sync="page"
        :server-items-length="totalItems"
        :loading="loading"
        :loading-text="$t('Loading data')"
        :no-data-text="$t('No data')"
        :no-results-text="$t('No result')"
        hide-default-footer
        :show-select="true"
        dense fixed-header height="500"
        @page-count="pageCount = $event"
      >
        <template #body.prepend="{ items, headers }">
          <header-filters :headers="filters" :show-select="true"/>
        </template>
        <template #item="options">
          <Row
            :options="options"
            :show-select="true"
            completed-field="publication.state"
            :config="{ url: '/subscriptions/action/', author: 'author', resource: 'Subscription'}"
            @refetch-data="fetchItems"
            @saved="saved"
            @select="select"
          >
        <!-- name -->

            <template #[`item.user.name`]="{item}">
              <div class="d-flex align-center">
                <v-avatar
                  v-if="item.user"
                  :color="item.user.avatar ? '' : 'primary'"
                  :class="item.user.avatar ? '' : 'v-avatar-light-bg primary--text'"
                  size="30"
                >
                  <v-img
                    v-if="item.user.avatar"
                    :lazy-src="require(`@/assets/images/avatars/2.png`)"
                    :src="item.user.avatar || require(`@/assets/images/avatars/2.png`)"
                  ></v-img>
                  <span
                    v-else
                    class="font-weight-medium"
                  >{{ avatarText(item.user.name) }}</span>
                </v-avatar>

                <div class="d-flex flex-column ms-3" v-if="item.user">
                  <router-link
                    :to="{ name : 'user-view', params : { id : item.user_id } }"
                    class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
                  >
                    {{ item.user.name }}
                  </router-link>
                  <!--              <small>@{{ item.manuscriptname }}</small>-->
                </div>
              </div>
            </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                :disabled="isCompleted(item.publication)"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <!--              <v-list-item
                :to="{name:'subscription-view',params:{id:item.id}}"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>{{ $t('Details') }}</span>
                </v-list-item-title>
              </v-list-item>-->

              <v-list-item
                v-if="$can('edit','Subscription')"
                @click="openDialog(item)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiPencilOutline }}
                  </v-icon>
                  <span>{{ $t('Update') }}</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="$can('delete','Subscription')"
                @click="confirmDelete(item)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span><span>{{ $t('Delete') }}</span></span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
          </Row>
        </template>
      </v-data-table>
      <v-card-text class="pt-2">
        <v-row>
          <v-col
            lg="2"
            cols="3"
          >
            <rows-per-page :options="options" />
          </v-col>

          <v-col
            lg="10"
            cols="9"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="page"
              total-visible="6"
              :length="pageCount"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="deleteDialog"
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 300 : '100%'"
    >
      <v-card>
        <v-card-title>{{ $t("Confirm delete") }}</v-card-title>
        <v-divider />
        <v-card-text>{{ $t("Are you sure to delete?") }}</v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            small
            outlined
            @click="deleteDialog = false"
          >
            {{ $t("No") }}
          </v-btn>
          <v-spacer />
          <v-btn
            small
            color="primary"
            :loading="loading"
            @click="deleteItem"
          >
            {{ $t("Yes") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="isSnackbarBottomVisible"
      :color="$vuetify.theme.currentTheme.error"
      :light="$vuetify.theme.dark"
    >
      {{ snackBarMessage }}
    </v-snackbar>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiPlus,
  mdiClose,
  mdiMicrosoftExcel,
  mdiFileDocumentOutline,
  mdiDeleteOutline,
  mdiExportVariant,
  mdiAccountOutline,
  mdiPencilOutline,
  mdiCheckCircle,
  mdiCheckOutline,
  mdiCalendar,
} from '@mdi/js'
import { onUnmounted, ref } from '@vue/composition-api'

// sidebar
import { avatarText, formatDate } from '@core/utils/filter'
import axios from '@axios'
import store from '@/store'
import SubscriptionForm from './SubscriptionForm.vue'
import itemStoreModule from './itemStoreModule'

import controller from './Controller'
import RowsPerPage from '@/components/RowsPerPage'


import HeaderFilters from '@/components/HeaderFilters'
import ExportExcel from '@/components/ExportExcel'

import TableRow from '@/components/TableRow'
import Row from "@/components/Row";


export default {
  components: {
    Row,
    TableRow,
    ExportExcel,
    HeaderFilters,
    RowsPerPage,
    SubscriptionForm,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-subscription'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, itemStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const {
      saved,
      item,
      items,
      downloadDialog,
      downloadObject,
      signatureDialog,
      accordDialog,
      tableColumns,
      filters,
      searchQuery,
      payFilter,
      sourceFilter,
      statusFilter,
      totalItems,
      loading,
      options,
      subscriptionTotalLocal,
      selectedRows,
      select,
      isFormActive,
      isPropositionActive,
      snackBarMessage,
      isSnackbarBottomVisible,
      isRelanceActive,
      openRelanceDialog,
      openDialog,
      openPropositionDialog,
      openSignatureDialog,
      openAccordDialog,
      deleteItem,
      deleteDialog,
      confirmDelete,
      fetchItems,
      resolveSubscriptionRoleVariant,
      resolveSubscriptionRoleIcon,
      resolveSubscriptionStatusVariant,
      resolveSubscriptionStatusName,
      resolveSubscriptionTotalIcon,
    } = controller()

    const superiors = ref([])

    const payOptions = [
      { title: 'Admin', value: 'admin' },
      { title: 'Subscription', value: 'subscription' },
      { title: 'Editor', value: 'editor' },
      { title: 'Maintainer', value: 'maintainer' },
      { title: 'Subscriber', value: 'subscriber' },
    ]

    const planOptions = [
      { title: 'Basic', value: 'basic' },
      { title: 'Company', value: 'company' },
      { title: 'Enterprise', value: 'enterprise' },
      { title: 'Standard', value: 'standard' },
    ]

    const statusOptions = [
      { title: 'Active', value: 1 },
      { title: 'Inactive', value: 0 },
    ]

    /* store
      .dispatch('app-subscription/fetchCountries', { per_page: 500 }) */
    /* store
      .dispatch('app-subscription/fetchSources', { per_page: 500 }) */
    /* store
      .dispatch('app/fetchGenres', { per_page: 500 }) */
    /* store
      .dispatch('app-subscription/fetchFormats', { per_page: 500, type: 'subscription' }) */
    /* store
      .dispatch('app-subscription/fetchStatuses', { per_page: 500, status_type: 'subscription' }) */

    axios
      .get('/countries', { params: { per_page: 500, type: 'subscription' } })
      .then(response => {
        store.state['app-subscription/countries'] = response.data.data
        const indexH = filters.value.findIndex(ele => ele.value === 'country.full_name')
        if (indexH > 0) {
          filters.value[indexH].filter.values = response.data.data
        }
      }).catch(error => console.log(error))
   /* axios
      .get('/statuses', { params: { per_page: 500, type: 'subscription' } })
      .then(response => {
        store.state['app-subscription/statuses'] = response.data.data
        const indexH = filters.value.findIndex(ele => ele.value === 'status')
        if (indexH > 0) {
          filters.value[indexH].filter.values = response.data.data
        }
      }).catch(error => console.log(error))*/
    axios
      .get('/sources', { params: { per_page: 500, type: 'subscription' } })
      .then(response => {
        store.state['app/sources'] = response.data.data
        const indexH = filters.value.findIndex(ele => ele.value === 'source.source_name')
        if (indexH > 0) {
          filters.value[indexH].filter.values = response.data.data
        }
        const indexO = filters.value.findIndex(ele => ele.value === 'origin.source_name')
        if (indexO > 0) {
          filters.value[indexO].filter.values = response.data.data
        }
      }).catch(error => console.log(error))
    axios
      .get('/formats', { params: { per_page: 500, type: 'subscription' } })
      .then(response => {
        store.state['app-subscription/formats'] = response.data.data
        const indexH = filters.value.findIndex(ele => ele.value === 'format.name')
        if (indexH > 0) {
          filters.value[indexH].filter.values = response.data.data
        }
      })
      .catch(error => console.log(error))
    axios.get('/genres', { params: { per_page: 500, type: 'subscription' } })
      .then(response => {
        store.state['app/genres'] = response.data.data
        const indexH = filters.value.findIndex(ele => ele.value === 'genre.label')
        if (indexH > 0) {
          filters.value[indexH].filter.values = response.data.data
        }
      })
      .catch(error => console.log(error))

    filters.value = tableColumns


    return {
      saved,
      item,
      items,
      downloadDialog,
      downloadObject,
      superiors,
      filters,
      tableColumns,
      searchQuery,
      payFilter,
      sourceFilter,
      statusFilter,
      totalItems,
      payOptions,
      planOptions,
      statusOptions,
      loading,
      options,
      accordDialog,
      signatureDialog,
      subscriptionTotalLocal,
      isPropositionActive,
      isFormActive,
      selectedRows,
      select,
      snackBarMessage,
      isSnackbarBottomVisible,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      openDialog,
      isRelanceActive,
      openRelanceDialog,
      openPropositionDialog,
      openSignatureDialog,
      openAccordDialog,
      deleteItem,
      deleteDialog,
      confirmDelete,
      avatarText,
      formatDate,
      resolveSubscriptionRoleVariant,
      resolveSubscriptionRoleIcon,
      resolveSubscriptionStatusVariant,
      resolveSubscriptionStatusName,
      resolveSubscriptionTotalIcon,
      fetchItems,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiMicrosoftExcel,
        mdiPlus,
        mdiClose,
        mdiExportVariant,
        mdiAccountOutline,
        mdiPencilOutline,
        mdiCheckCircle,
        mdiCheckOutline,
        mdiCalendar,
      },
    }
  },
}
</script>
<style lang="scss">
  /*table {

    background-color: white;
    margin: auto;
    border-collapse: separate;
    border-spacing: 0;
    display: block;
    overflow-x: scroll;
  }
  .v-data-table td, th {
    margin: 0;
    border: 1px solid grey;
    white-space: nowrap;
    border-top-width: 0px;
  }

  .v-data-table thead tr:first-child
  }
  .fixed-column {
    position: absolute;
    width: 5em;
    left: 0;
    top: auto;
    border-top-width: 1px;
    margin-top: -1px;
  }*/
</style>
<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>

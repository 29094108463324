<template>
  <v-form
    ref="form"
    v-model="valid"
    @submit.prevent="onSubmit"
  >
    <v-card
      height="100%"
      flat
    >
      <v-card-title>
        <slot name="form-title">
          <span class="font-weight-semibold text-base text--primary">Add New User</span>
          <v-spacer />
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="$emit('update:is-form-active',false)"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </slot>
      </v-card-title>

      <v-card-text
        class="overflow-y-auto"
        style="max-height: 500px;"
      >
        <v-row class="pt-5">
          <v-col
            cols="12"
          >
            <v-text-field
              v-model="item.name"
              outlined
              dense
              :rules="[validators.required]"
              :error-messages="errorMessages.name"
              :label="$t('Name')"
              :placeholder="$t('Name')"
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
              v-model="item.firstname"
              outlined
              dense
              :rules="[validators.required]"
              :error-messages="errorMessages.lastname"
              :label="$t('First name')"
              :placeholder="$t('First name')"
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
              v-model="item.lastname"
              outlined
              dense
              :rules="[validators.required]"
              :error-messages="errorMessages.lastname"
              :label="$t('Last name')"
              :placeholder="$t('Last name')"
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
              v-model="item.email"
              :rules="[validators.required/*, validators.emailValidator*/]"
              outlined
              type="text"
              :label="$t('Email')"
              :placeholder="$t('Email')"
              :error-messages="errorMessages.email"
              dense
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
              v-model="item.tel"
              outlined
              dense
              :label="$t('Phone')"
              :placeholder="$t('Phone')"
              :error-messages="errorMessages.phone"
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            v-if="$can('add_credit','User')"
          >
            <v-text-field
              v-model="item.credit"
              outlined
              dense
              :label="$t('Credit')"
              :placeholder="$t('Credit')"
              :error-messages="errorMessages.credit"
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col
            v-if="withPassword"
            cols="12"
            sm="6"
          >
            <v-text-field
              v-model="item.password"
              outlined
              :rules="[ ((item.id && item.changePassword) || !item.id) ?validators.required:true,]"
              :error-messages="errorMessages.password"
              :label="$t('Password')"
              placeholder="············"
              :readonly="!item.changePassword"
              :append-icon="item.changePassword ? icons.mdiPencilOffOutline : icons.mdiPencilOutline"
              dense
              hide-details="auto"
              @click:append="item.changePassword = !item.changePassword"
            ></v-text-field>
          </v-col>

          <v-col
            v-if="withPassword"
            cols="12"
          >
            <v-autocomplete
              v-model="item.roles_names"
              :label="$t('Rôle')"
              :items="$store.state['app-user'].roles"
              :item-text="`display_name.${$i18n.locale}`"
              item-value="name"
              chips
              clearable
              deletable-chips
              multiple
              outlined
              dense
              hide-details="auto"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <slot name="form-actions">
          <slot name="form-cancel">
            <v-btn
              :loading="loading"
              color="secondary"
              outlined
              type="reset"
              @click="resetItem"
            >
              {{ $t('Cancel') }}
            </v-btn>
          </slot>
          <v-spacer />

          <v-btn
            :loading="loading"
            color="primary"
            class="me-3"
            type="submit"
          >
            {{ $t("Save") }}
          </v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { mdiClose, mdiPencilOutline, mdiPencilOffOutline } from '@mdi/js'
import { computed, ref, watch } from '@vue/composition-api'
import { required, emailValidator } from '@core/utils/validation'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import useUsersList from './useUsersList'

export default {
  model: {
    prop: 'object',
    event: 'update:is-form-active',
  },
  props: {
    object: {
      type: Object,
      default: () => {},
    },
    isFormActive: {
      type: Boolean,
      required: true,
    },
    withPassword: {
      type: Boolean,
      default: true,
    },
    roleOptions: {
      type: Array,
      default: () => [],
    },
    planOptions: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const {
      form,
      isSnackbarBottomVisible,
      snackBarMessage,
      fetchUsers,
      resetForm,
    } = useUsersList()
    const users = ref([])
    const errorMessages = ref({ })
    const valid = ref(false)
    const loading = ref(false)
    const isLoadingUsers = ref(false)
    const quotas = ref([])
    for (let i = 10; i <= 100; i += 10) {
      quotas.value.push(i)
    }
    const validate = () => {
      form.value.validate()
    }

    const item = computed({
      get: () => props.object,
      set: value => emit('update:object', value),
    })

    // const item = ref(JSON.parse(JSON.stringify(blankItem)))
    const resetItem = () => {
      // item.value = {}
      // resetForm()

      // emit('update:object', item.value)
    }

    const onSubmit = () => {
      if (valid.value) {
        loading.value = true
        store.dispatch('app-user/addUser', item.value)
          .then(response => {
            loading.value = false
            if (response.data.error || response.data.errors || (response.data.status && response.data.status === 'false')) {
              if (response.data.errors) {
                // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
                errorMessages.value = { ...response.data.errors }
              } else {
                isSnackbarBottomVisible.value = true
                snackBarMessage.value = response.data.message

                // this.toast(response.data.message, this.$vuetify.theme.currentTheme.error)
              }

              return false
            }
            resetItem()

            // emit('update:is-form-active', false)

            emit('refetch-data')
            store.dispatch('app-user/searchUsers', { term: '', rowsPerPage: 100 }).then(response => {
              isLoadingUsers.value = false

              users.value = response.data
            })
          }).catch(error => {
            loading.value = false
            console.log('error', error)
            if (error.data.errors) {
            // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
              errorMessages.value = { ...error.data.errors }
            }
          })
      } else {
        validate()
      }
    }
    const search = ref(null)
    store.dispatch('app-user/searchUsers', { term: '', rowsPerPage: 100 }).then(response => {
      isLoadingUsers.value = false

      users.value = response.data
    })

    // onMounted(searchParticular)
    watch(search, val => {
      if (!val || val.trim().length < 3 || isLoadingUsers.value) return ''
      isLoadingUsers.value = true
      store.dispatch('app-user/searchUsers', { term: val, rowsPerPage: 100 }).then(response => {
        isLoadingUsers.value = false

        users.value = response.data
      })

      return ''
    })
    // eslint-disable-next-line no-unused-vars
    /* watch(() => props.object, (newValue, prevValue) => {
      if (!newValue || !newValue.id) {
        resetItem()
      } else {
        item.value = JSON.parse(JSON.stringify(newValue))
      }
    }) */
    // eslint-disable-next-line no-unused-vars
    /* watch(() => item, (newValue, prevValue) => {
      console.log('item change', newValue.value)
      emit('input', newValue.value)
    }, { deep: true }) */

    return {
      resetItem,
      form,
      isLoadingUsers,
      isSnackbarBottomVisible,
      snackBarMessage,
      errorMessages,
      onSubmit,
      countries,
      item,
      valid,
      users,
      search,
      loading,
      validate,
      quotas,
      // validation
      validators: { required, emailValidator },
      icons: {
        mdiClose,
        mdiPencilOffOutline,
        mdiPencilOutline,
      },
    }
  },
}
</script>

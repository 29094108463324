<template>
  <tr
    :id="'tr-'+row.item.id || 0"
    :class="{'completed': getItemValue(row.item, completedField) >= $store.state.app.states.RESILIATION_STATE }"
  >
    <template v-for="(th,index) in row.headers">
      <td
        v-if="th.value === 'data-table-select'"
        class="fixed-column"
      >
        <v-checkbox
          v-model="selected"
          :disabled="getItemValue(row.item, completedField) >= $store.state.app.states.RESILIATION_STATE"
        />
      </td>
      <td
        v-else
        :id="'td-'+th.value+'-'+row.item.id || index"
        :key="th.value"
        :class="th.cellClass || ''"
        :style="{'text-align': th.align || 'left', width: th.width || '1px' }"
      >
        <cell
          :options="row.item"
          :th="th"
          :config="config"
          @saved="saved"
        >
          <template #data="{ item }">
            <slot
              :name="'item.'+th.value"
              :item="row.item"
            >
              <!--              {{ getItemValue(row.item, th.value) }}-->
            </slot>
          </template>
        </cell>
      </td>
    </template>
  </tr>
</template>

<script>

// eslint-disable-next-line object-curly-newline
import {
  mdiCalendar,
  mdiCalendarRange,
  mdiFilter,
} from '@mdi/js'
import { computed, ref, watch } from '@vue/composition-api'
import { avatarText, formatDate } from '@core/utils/filter'
import { i18n } from '@/plugins/i18n'
import Cell from '@/components/Cell'

export default {
  components: { Cell },
  props: {
    options: {
      type: Object,
      default: () => [],
    },
    showSelect: {
      type: Boolean,
      default: false,
    },
    completedField: {
      type: String,
      default: 'state',
    },
    config: {
      type: Object,
      default: () => ({ url: '/' }),
    },
  },
  setup(props, { emit }) {
    const today = ref((new Date(Date.now())).toISOString().substr(0, 10))
    const row = computed({
      get: () => props.options,
      set: value => emit('update:object', value),
    }, { deep: true })
    const stopNext = () => {
      console.log('parent clicked')
    }

    watch(row, val => {
      emit('update:object', val)
    }, { deep: true })
    const selected = computed({
      get: () => row.value.isSelected,
      set: value => {
        emit('select', { state: value, item: row.value.item, index: row.value.index })
      },
    })
    const saved = data => {
      row.value.item = data
      console.log('item changed', row.value.item)
      emit('saved', { item: data, index: row.value.index })
      emit('refetch-data')
    }
    watch(selected, val => {
      // console.log(val, row.value.index)
      // emit('select', { value: val, item: row.value.item, index: row.value.index })
    }, { deep: true })

    return {
      saved,
      formatDate,
      row,
      today,
      stopNext,
      selected,
      icons: {
        mdiCalendar,
        mdiCalendarRange,
        mdiFilter,
      },
    }
  },
}
</script>

<template>
  <div class="justify-center d-flex">
    <v-avatar
      :color="item.avatar ? '' : 'primary'"
      :class="item.avatar ? '' : 'v-avatar-light-bg primary--text'"
      size="30"
      rounded
    >
      <v-img
        v-if="item.avatar"
        class="  my-auto"
        :lazy-src="require(`@/assets/images/avatars/1.png`)"
        :src="item.avatar || require(`@/assets/images/avatars/1.png`)"
      ></v-img>
      <span
        v-else
        class="font-weight-semibold text-body-2"
      >{{ avatarText(item.label ) }}</span>
    </v-avatar>
    <span v-if="item.publication" class="ml-1 my-auto">{{ item.publication }} - </span>
    <span class="ml-1 my-auto">{{ item.label }}</span>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'

export default {
  props: {
    options: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const item = computed({
      get: () => props.options,
      set: value => emit('update:object', value),
    })

    return {
      item,
      avatarText,
    }
  },
}
</script>
